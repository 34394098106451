export const codeExplanations = [
  {
    id: 1,
    introText: 'Sometimes, you have to look between the lines and check twice your mailbox to find what you needed. I found this code:',
    labelText: 'I found this code:',
    text: 'The first LOUIS XIII cognac was created by Paul-Émile Rémy Martin in <span class="text-quinary font-sangbleukingdom">1874</span>.',
    code: '1874'
  },
  {
    id: 2,
    introText: '',
    labelText: '',
    text: 'LOUIS XIII is an exceptional blend of the oldest and most precious eaux-de-vie. This succession of blends creates a balance between the different aromas such as <span class="text-quinary font-sangbleukingdom">leather</span> for instance and contributes to the smooth harmony of LOUIS XIII.',
    code: 'leather'
  },
  {
    id: 3,
    introText: 'This enigma was delivered on the explanatory pages of the experience. Do you remember it?',
    labelText: 'I found this code:',
    text: 'You are about to enter The LOUIS XIII Mysteries, a secret and unsung place beyond time and space. In this universe, your mission will be to find <span class="text-quinary font-sangbleukingdom">XIII</span> keys to unlock your chance to live a sensorial LOUIS <span class="text-quinary font-sangbleukingdom">XIII</span> experience. Find the hidden codes in each enigma to unlock the keys. One code might already be in these messages…',
    code: 'xiii'
  },
  {
    id: 4,
    introText: '',
    labelText: '',
    text: 'The <span class="text-quinary font-sangbleukingdom">taste</span> of LOUIS XIII cognac is a multiple facets experience. LOUIS XIII is best savoured drop by drop. Place a drop on your lips first, to prepare the palate.',
    code: 'taste'
  },
  {
    id: 5,
    introText: 'For more than ten years, I tested, rated and watched over several thousand eaux-de-vie and selected them to compose LOUIS XIII cognac. I discovered LOUIS XIII Rare Cask, a pure moment of grace in the career of a cellar master. Who am I?',
    labelText: 'Who am I?',
    text: 'For more than ten years, I tested, rated and watched over several thousand eaux-de-vie and selected them to compose LOUIS XIII cognac. I discovered LOUIS XIII Rare Cask, a pure moment of grace in the career of a cellar master.',
    code: 'pierrette trichet'
  },
  {
    id: 6,
    introText: '',
    labelText: '',
    text: 'The Cellar Master inspects and samples each <span class="text-quinary font-sangbleukingdom">cask</span> every year, judging evolution with an expert nose and selecting certain casks to be <span class="text-quinary font-sangbleukingdom">_______</span> with certain others.',
    completeText: 'The Cellar Master inspects and samples each <span class="text-quinary font-sangbleukingdom">cask</span> every year, judging evolution with an expert nose and selecting certain casks to be <span class="text-quinary font-sangbleukingdom">blended</span> with certain others.',
    code: 'cask'
  },
  {
    id: 7,
    introText: '',
    labelText: '',
    text: 'The Cellar Master inspects and samples each <span class="text-quinary font-sangbleukingdom">____</span> every year, judging evolution with an expert nose and selecting certain casks to be <span class="text-quinary font-sangbleukingdom">blended</span> with certain others.',
    completeText: 'The Cellar Master inspects and samples each <span class="text-quinary font-sangbleukingdom">cask</span> every year, judging evolution with an expert nose and selecting certain casks to be <span class="text-quinary font-sangbleukingdom">blended</span> with certain others.',
    code: 'blended'
  },
  {
    id: 8,
    introText: '',
    labelText: '',
    text: 'Succeeding Pierrette Trichet, Baptiste <span class="text-quinary font-sangbleukingdom">Loiseau</span> became our youngest LOUIS XIII Cellar Master in 2014.',
    code: 'loiseau'
  },
  {
    id: 9,
    introText: '',
    labelText: '',
    text: 'Dictated by <span class="text-quinary font-sangbleukingdom">seasonal</span> cycles, the synergy of time and nature enters into play throughout the production process. Each year comes with its own challenges, every year brings unpredictable climatic conditions which give a different profile to the must, to the wine and eau-de-vie.',
    code: 'seasonal'
  },
  {
    id: 10,
    introText: 'The truth is hidden in tiny and subtle details…<br><br>At LOUIS XIII we continuously m<span class="text-quinary font-sangbleukingdom">a</span>intain a careful balance of safeguarding our heritage and se<span class="text-quinary font-sangbleukingdom">c</span>uring the future of our cognac. The long-term collaboration with the Office National des F<span class="text-quinary font-sangbleukingdom">o</span>rêts, or ONF, enters into this equation. We have been <span class="text-quinary font-sangbleukingdom">c</span>ollaborating on a number of reforestation projects for over a decade with the goal of protecting the forest, encouraging biodiversity but also raising public aware<span class="text-quinary font-sangbleukingdom">n</span>ess. It is a mutually beneficial collaboration, stren<span class="text-quinary font-sangbleukingdom">g</span>thened by the fact we share the same vocabulary: the vocabulary of exception, time, patience and humility. What’s the code?',
    labelText: 'What\'s the code?',
    text: 'The truth is hidden in tiny and subtle details…<br><br>At LOUIS XIII we continuously m<span class="text-quinary font-sangbleukingdom">a</span>intain a careful balance of safeguarding our heritage and se<span class="text-quinary font-sangbleukingdom">c</span>uring the future of our cognac. The long-term collaboration with the Office National des F<span class="text-quinary font-sangbleukingdom">o</span>rêts, or ONF, enters into this equation. We have been <span class="text-quinary font-sangbleukingdom">c</span>ollaborating on a number of reforestation projects for over a decade with the goal of protecting the forest, encouraging biodiversity but also raising public aware<span class="text-quinary font-sangbleukingdom">n</span>ess. It is a mutually beneficial collaboration, stren<span class="text-quinary font-sangbleukingdom">g</span>thened by the fact we share the same vocabulary: the vocabulary of exception, time, patience and humility.',
    code: 'cognac'
  },
  {
    id: 11,
    introText: 'Explore more than this universe… Have a walk on one of LOUIS XIII’s social networks!',
    labelText: 'I found this code:',
    text: 'The aim: unlock your place to try to assist at a luxury tasting.',
    code: 'experience'
  },
  {
    id: 12,
    introText: 'There is another universe you didn’t explore yet… Everything started with a face and ended with a book.*',
    labelText: 'I found this code:',
    annotation: '*The key to this enigma can only be found on the networks intended for the US',
    text: 'In 1948, LOUIS XIII cognac had the pleasure of serving Queen Elizabeth II, <span class="text-quinary font-sangbleukingdom">King</span> George VI and Churchill.',
    code: 'kings'
  },
  {
    id: 13,
    introText: 'Behind a welcome you will find a code. Our website is full of beautiful words.',
    labelText: 'I found this code:',
    text: 'For the connoisseurs, LOUIS XIII cognac is not only a story of taste. It is a moment of complicit pleasure to savor in specific conditions to be appreciated.',
    code: 'synesthetic'
  }
]
