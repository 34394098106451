<template>
  <div class="text-mystery-container transition opacity-0 relative flex flex-col justify-center px-8" :class="'bg-text-mystery-' + backgroundId">
    <div class="code-fade-top z-20 transition duration-1000 opacity-100"></div>
    <div class="code-fade-bottom z-20 transition duration-1000 opacity-100"></div>
    <div class="h-full relative">
      <!-- MYSTERY CONTENT -->
      <div class="h-full pt-40 pb-20 sm:pt-40 sm:pb-20 overflow-y-scroll absolute z-10 sm:mx-auto delay-700 transition duration-1000 top-1/2 transform scrolling-text" :class="{'opacity-0 z-0 -translate-y-full': isCodeFound, '-translate-y-1/2': !isCodeFound}">
        <ValidationObserver
          v-slot="{handleSubmit}"
          ref="codeObserver"
          tag="form"
          @submit.prevent
        >
          <div class="sm:pl-20 sm:w-4/5 mr-2">
            <p class="text-xl md:text-xxl leading-tight" v-html="codeExplanations[id-1].introText"></p>
            <p class="text-xl md:text-xxl text-quinary mt-8" v-html="codeExplanations[id-1].labelText"></p>
            <NText
              id="input-code"
              :name="'code'"
              type="text"
              v-model="code"
              rules="required|alphaNum"
              animationType="above"
              errorTrigger="blur"
              designType="line"
              :textColor="colorStyle.quinary"
              :backgroundColor="'transparent'"
              :borderColor="'transparent'"
              :errorColor="colorStyle.error"
              :showIcons="false"
              :placeholder="'My answer...'"
            ></NText>
          </div>
          <div>
            <NCtaLoader
              ref="myCtaLoader"
              class="my-cta-loader transition duration-1000"
              :class="{'opacity-50': !code}"
              :borderColor="{ default: colorStyle.quinary, hover: colorStyle.quinary, success: colorStyle.quinary, error: colorStyle.error }"
              :background-color="{ default: 'transparent', hover: 'transparent', success: colorStyle.quinary, error: colorStyle.error }"
              @click.native="handleSubmit(submitCode)"
            >
              SUBMIT MY CODE
            </NCtaLoader>
          </div>
          <div class="sm:pl-20 mt-4 sm:mt-10 annotation italic text-gray-300 text-xs" v-if="codeExplanations[id-1].annotation">
            <p v-html="codeExplanations[id-1].annotation"></p>
          </div>
        </ValidationObserver>
      </div>
      <!-- RESULT -->
      <div class="h-full pt-40 pb-20 overflow-y-scroll absolute sm:mx-auto delay-1000 transition duration-1000 top-1/2 transform" :class="{'-translate-y-1/2 z-10': isCodeFound, 'ranslate-y-0 opacity-0 z-0': !isCodeFound}">
        <div class="relative code-reveal py-10 mr-2 transition duration-1000" :class="{'opacity-0': !isCodeFound}">
          <div class="sm:w-4/5 sm:mx-auto">
            <p class="text-lg mb-4">Code <span v-if="codeFoundId">{{codeFoundId}}</span></p>
            <p class="text-xl md:text-xxl" v-if="codeFoundId" v-html="codeExplanations[codeFoundId-1].text"></p>
          </div>
          <div class="text-center mt-8">
            <button class="button-code border-solid border-quinary text-sm cursor-pointer overflow-hidden" @click="goToHub()">
              <span class="block">CONTINUE</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import design from '@/validators/design'
import { codeExplanations } from '@/data'
import { NText } from '@team-uep/n-inputs'
import gsap from "gsap"
export default {
  name: 'TextMystery',
  props: {
    id: {
      type: Number,
      required: true,
      default: 1
    },
    backgroundId: {
      type: String,
      required: true,
      default: '1'
    }
  },
  components: {
    NText
  },
  data () {
    return {
      code: '',
      codeFound: false,
      codeFoundId: 0,
      isLoading: false,
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      codeExplanations: codeExplanations
    }
  },
  computed: {
    ...mapGetters({
      lastEnigmaId: "user/lastEnigmaId"
    }),
    isCodeFound () {
      return this.codeFoundId && this.codeFound
    }
  },
  mounted () {
    let tlContainer = gsap.timeline()
    tlContainer.to('.text-mystery-container', {duration: 1, opacity: 1})
  },
  methods: {
    goToHub() {
      this.$router
        .push({ name: 'hub', params: { swiperIndex: this.lastEnigmaId || '1' } })
        .catch(console.log)
    },
    resetLoaderButton() {
      this.$refs.myCtaLoader.reset()
      this.isLoading = false
    },
    resetForm() {
      this.code = ''
      this.$nextTick(() => {
        this.$refs.codeObserver.reset()
      })
      this.resetLoaderButton()
      this.codeFound = false
      setTimeout(() => {
        this.codeFoundId = 0
      }, 1000)
    },
    setInvalidCode() {
      this.$refs.myCtaLoader.endLoading(false, 'Invalid code')
        .then(() => {
          this.isLoading = false
          this.codeFound = false
          this.codeFoundId = 0
        })
    },
    submitCode() {
      // Start the loader
      if (!this.isLoading) {
        this.isLoading = true
        this.$refs.myCtaLoader.startLoading()
        if (this.codeExplanations[this.id-1].code != this.code.toLowerCase()) {
          this.setInvalidCode()
        } else {
          this.$requestAPI
            .qualifRegister({
              oResponses : { 
                sCode: this.code.toLowerCase(),
              }
            }).then((r)=>{
              console.log('res', r)
              
              this.$refs.myCtaLoader.endLoading(true)
                .then(() => {
                  this.$gtmTracking.keyFound()
                  this.isLoading = false
                  this.codeFound = true
                  this.codeFoundId = this.codeExplanations.filter((item) => {
                    return item.code === this.code.toLowerCase()
                  })[0].id
                  console.log('this.codeFoundId', this.codeFoundId)
                })
            })
            .catch((error)=> {
              console.log('error', error)
              this.setInvalidCode()
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  $ids: 1, 3, 5, 10, 11, 12, 13;

  .text-mystery-container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    @each $id in $ids {
      &.bg-text-mystery-#{$id} {
        background: url('../assets/bg/text-mystery/#{$id}-min.jpg');
        background-size: cover;
        @media screen and (min-width: 768px) {
          background: url('../assets/bg/text-mystery/#{$id}.jpg');
          background-size: cover;
        }
      }
    }
    .scrolling-text {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .scrolling-text::-webkit-scrollbar {
      display: none;
    }
    .code-fade-top, .code-fade-bottom {
      position: absolute;
      left: 0;
      width: 100%;
      height: 10%;
    }
    .code-fade-top {
      top: 0;
      background: linear-gradient(to bottom, rgba(10,10,10,1) 20%,rgba(0,0,0,0) 100%);
      @media screen and (min-width: 768px) {
        background: linear-gradient(to bottom, rgba(10,10,10,1) 5%,rgba(0,0,0,0) 100%);
      }
    }
    .code-fade-bottom {
      bottom: 0;
      background: linear-gradient(to top, rgba(10,10,10,1) 20%,rgba(0,0,0,0) 100%);
      @media screen and (min-width: 768px) {
        background: linear-gradient(to top, rgba(10,10,10,1) 5%,rgba(0,0,0,0) 100%);
      }
    }
    .button-code {
      border-width: 1px;
      width: 198px;
      outline: none;
      padding: 0.8em 1.3em;
    }
  }
  ::v-deep .n-input.n-input-line div input {
    font-size: 28px;
    @media screen and (min-width: 768px) {
      font-size: 48px;
    }
    padding-top: 0;
    border: none;
  }
  ::v-deep .my-cta-loader {
    width: 198px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    position: relative;
    .n-cta-loader {
      border-width: 1px;
      border-style: solid;
      padding: 0.8em 1.3em;
      &.is-loading:not(.is-completed) .n-cta-loader__progress-bar {
        background-color: rgb(232, 206, 164) !important;
      }
      &.is-loading:not(.is-completed) .n-cta-loader__content {
        color: black;
      }
    }
    svg {
      margin-left: auto;
      margin-right: auto;
      margin-top: 5px;
    }
    .error-text {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      bottom: -25px;
    }
    .is-completed.error {
      border-color: rgb(255, 59, 48) !important;
    }
  }
</style>