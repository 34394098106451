<template>
  <div class="enigma-container">
    <overlay-navigation class="absolute w-full h-full" :hide-info="true" />
    <text-mystery :background-id="'1'" :id="1" />
  </div>
</template>

<script>
import OverlayNavigation from '@/components/OverlayNavigation.vue'
import TextMystery from '@/components/TextMystery.vue'
export default {
  name: 'Enigma1',
  components: {
    OverlayNavigation,
    TextMystery
  },
  mounted() {
    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
  }
}
</script>

<style lang="scss" scoped>
</style>